<template>
  <div>
    <div class="w-content">
      <form @submit.prevent="deploySmartHandler">
        <h2 class="title">Write a smart contract</h2>
        <input :disabled="disabledForm" class="main-input" placeholder="Input here fee value" id="Fee" v-model.trim="fee" />
        <div class="mb-20">
          <div id="editor"></div>
        </div>
        <div class="row mb-50">
          <router-link :to="`/${$i18n.locale}/wallet/create-token`" class="link fo_link">Back</router-link>
          <button type="submit" class="bold-link bttn margin_left_60" id="TokenDeploy" :disabled="disabledButton" :class="status($v.fee)">
            {{$t("CreateToken_button_2")}}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
  #editor {
    /* position: absolute; */
    width: 550px;
    height: 400px;
  }

  .error.dirty {
    background: #f1b0b7;
    border-color: #ff0000;
  }
</style>
<script>
  import {mapState} from "vuex";
  import instance from "../http";
  import i18n from "@/i18n";
  import {between, required} from "vuelidate/lib/validators";

  export default {
    data() {
      return {
        tokenTransactionData: {},
        responsePackTransaction: {},
        fee: 0,
        transactionSignature: "",
        smartSourceCode: null,
        aceEditor: null,
        disabledForm: false,
        disabledButton: false
      };
    },
    validations: {
      fee: {
        required,
        between: between(0.1, 100)
      }
    },
    mounted() {
      //init editor
      this.$loading(true);
      console.log("PACK TRANSACTION: DEPLOY SMART");
      this.aceEditor = ace.edit("editor");
      this.aceEditor.setTheme("ace/theme/twilight");
      let javaMode = ace.require("ace/mode/java").Mode;
      this.aceEditor.session.setMode(new javaMode());

      console.log("SmartContract Name: " + this.createSmartData.smartName);

      if (this.createSmartData.smartName === undefined) {
        this.smartSourceCode = this.getSmartExampleSourceCode();
      }
      else {
        this.smartSourceCode = this.getSmartSourceCode(
          this.createSmartData.symbol,
          this.createSmartData.smartName,
          parseInt(this.createSmartData.dec),
          parseFloat(this.createSmartData.amount)
        );
      }

      this.aceEditor.setValue(this.smartSourceCode);

      this.tokenTransactionData = this.createSmartData;

      this.$loading(false);
    },
    methods: {
      status(validation) {
        return {
          error: validation.$error,
          dirty: validation.$dirty
        }
      },

      deploySmartHandler: function () {
        if (this.$v.$invalid === true) {
          this.$v.$touch();
          console.log("adress is required");
          return;
        }

        console.log("EXECUTION TRANSACTION: DEPLOY SMART");
        console.log("response from package transation:");
        console.log(this.responsePackTransaction);
        //signature preparation
        console.log("privateKEY:");
        console.log(this.privateKey);

        console.log("transPackageStr:");

        let prvKey = this.privateKey;

        this.smartSourceCode = this.aceEditor.getValue();
        let _fee = this.fee.toString().replace(',', '.');
        this.tokenTransactionData.fee = parseFloat(_fee);
        console.log("SOURCE CODE:");
        //console.log(this.smartSourceCode);

        console.log(this.tokenTransactionData.methodApi);

        let postData = {
          publicKey: this.publicKey,
          //amount: parseFloat(this.tokenTransactionData.amount),
          methodApi: "SmartDeploy", //this.tokenTransactionData.methodApi,
          fee: parseFloat(_fee),
          smartContractSource: this.smartSourceCode,
          networkAlias: this.networkAlias
        };

        console.log("POST DATA:");
        console.log(postData);

      let headers = {
        "Content-Type": "application/json"
      };
      var _this = this;
      new Promise((resolve, reject) => {
        _this.disabledForm = true;
        _this.aceEditor.setReadOnly(true);
        _this.$loading(true);
        console.log(_this.disabledForm);

          instance
            .post("../../api/wallet/PackTransaction", postData, { headers })
            .then(r => {
              console.log("Response send  pack transaction:");
              console.log(r.data);
              this.responsePackTransaction = r.data;
              postData.transactionPackagedStr =
                r.data.dataResponse.transactionPackagedStr;
              this.$store.commit("SET_createTokenData", postData);
              // this.$router.push("../wallet/create-smart-result");
              alert("Pack transaction for deploy smart-contract completed!");
              _this.$loading(false);
              console.log(postData.smartContractSource);
              //_this.$loading(true);
              this.deploySmartExecuteTr(postData);
            })
            .catch(ex => {
              console.log(ex);
              console.log("Pack a smart - transaction error!");
            })
        });
      },
      deploySmartExecuteTr(data) {
        console.log("deploySmartExecuteTr");
        console.log("arg: data");
        console.log(data);
        let headers = {
          "Content-Type": "application/json"
        };
        let msgResult = "smart contract was created!";
        let _signature;
        try {
          _signature = nacl.sign.detached(
            Base58.decode(data.transactionPackagedStr),
            Base58.decode(this.privateKey)
          );
          data.transactionSignature = Base58.encode(_signature);
        } catch (error) {
          console.log(error);
          alert("Create signature completed with errors!");
        }
        this.$loading(true);
        setTimeout(() =>{
          if(this.$loading === true) {
            this.$loading(false);
            alert("The wait time has expired");
          }
        }, 10000);
        instance
          .post("../../api/wallet/executeTransaction", data, { headers })
          .then(r => {
            this.disabledForm = false;
            this.aceEditor.setReadOnly(false);
            console.log("RESPonse:");
            console.log(r);
            if (r.data.success === true) {
              console.log(msgResult);
              alert(msgResult);
              this.$loading(false);
              this.$router.push(`/${i18n.locale}/wallet/perform-transaction`);
            } else {
              msgResult = r.data.message;
              console.log(msgResult);
              alert(msgResult);
              this.$loading(false);
            }

          });
      },

      getSmartSourceCode: function (code, name, decimal, amount) {
        let source = `
import java.math.BigDecimal;
import java.util.HashMap;
import java.util.Map;
import java.util.Optional;

import com.credits.scapi.annotations.*;
import com.credits.scapi.v0.*;

import static java.math.BigDecimal.ROUND_FLOOR;
import static java.math.BigDecimal.ZERO;

public class Token${code} extends SmartContract implements ExtensionStandard {

    private final String owner;
    private final int decimal;
    HashMap<String, BigDecimal> balances;
    private String name;
    private String symbol;
    private BigDecimal totalCoins;
    private HashMap<String, Map<String, BigDecimal>> allowed;
    private boolean frozen;

    public Token${code}() {
        super();
        name = "${name}";
        symbol = "${code}";
        decimal = ${decimal};
        totalCoins = new BigDecimal(${amount.toString()}L).setScale(decimal, ROUND_FLOOR);
        owner = initiator;
        allowed = new HashMap<>();
        balances = new HashMap<>();
        balances.put(owner, new BigDecimal(${amount.toString()}L).setScale(decimal, ROUND_FLOOR));
    }

    @Override
    public int getDecimal() {
        return decimal;
    }

    @Override
    public void register() {
        balances.putIfAbsent(initiator, ZERO.setScale(decimal, ROUND_FLOOR));
    }

    @Override
    public boolean setFrozen(boolean isFrozen) {
        if (!initiator.equals(owner)) {
            throw new RuntimeException("unable change frozen state! The wallet "+ initiator + " is not owner");
        }
        this.frozen = isFrozen;
        return true;
    }

    @Override
    public String getName() {
        return name;
    }

    @Override
    public String getSymbol() {
        return symbol;
    }

    @Override
    public String totalSupply() {
        return totalCoins.toString();
    }

    @Override
    public String balanceOf(String owner) {
        return getTokensBalance(owner).toString();
    }

    @Override
    public String allowance(String owner, String spender) {
        if (allowed.get(owner) == null) {
            return "0";
        }
        BigDecimal amount = allowed.get(owner).get(spender);
        return amount != null ? amount.toString() : "0";
    }

    @Override
    public boolean transfer(String to, String amount) {
        contractIsNotFrozen();
        if (!to.equals(initiator)) {
            BigDecimal decimalAmount = toBigDecimal(amount);
            amountIsValid(decimalAmount);
            BigDecimal sourceBalance = getTokensBalance(initiator);
            BigDecimal targetTokensBalance = getTokensBalance(to);
            if (decimalAmount.compareTo(ZERO.setScale(decimal, ROUND_FLOOR)) < 0) {
                throw new RuntimeException("invalid amount");
            }
            if(targetTokensBalance == null)
            {
                targetTokensBalance = new BigDecimal(0).setScale(decimal, ROUND_FLOOR);
            }
            if (sourceBalance.compareTo(decimalAmount) < 0) {
                throw new RuntimeException("the wallet"  + initiator + "doesn't have enough tokens to transfer");
            }
            balances.put(initiator, sourceBalance.subtract(decimalAmount));
            balances.put(to, targetTokensBalance.add(decimalAmount));
        }
        return true;
    }

    @Override
    public boolean transferFrom(String from, String to, String amount) {
        contractIsNotFrozen();

        if (!from.equals(to)) {
            BigDecimal sourceBalance = getTokensBalance(from);
            BigDecimal targetTokensBalance = getTokensBalance(to);
            if(targetTokensBalance == null)
            {
                targetTokensBalance = new BigDecimal(0).setScale(decimal, ROUND_FLOOR);
            }
            BigDecimal decimalAmount = toBigDecimal(amount);
            amountIsValid(decimalAmount);
            if (decimalAmount.compareTo(ZERO.setScale(decimal, ROUND_FLOOR)) < 0) {
                throw new RuntimeException("invalid amount");
            }
            if (sourceBalance.compareTo(decimalAmount) < 0)
                throw new RuntimeException("unable transfer tokens! The balance of " + from + " less then " + amount);

            Map<String, BigDecimal> spender = allowed.get(from);
            if (spender == null || !spender.containsKey(initiator))
                throw new RuntimeException("unable transfer tokens! The wallet " + from + " not allow transfer tokens for " + to);

            BigDecimal allowTokens = spender.get(initiator);
            if (allowTokens.compareTo(decimalAmount) < 0) {
                throw new RuntimeException("unable transfer tokens! Not enough allowed tokens. For the wallet " + initiator + " allow only " + allowTokens + " tokens");
            }

            spender.put(initiator, allowTokens.subtract(decimalAmount));
            balances.put(from, sourceBalance.subtract(decimalAmount));
            balances.put(to, targetTokensBalance.add(decimalAmount));
        }
        return true;
    }

    @Override
    public void approve(String spender, String amount) {
        initiatorIsRegistered();
        BigDecimal decimalAmount = toBigDecimal(amount);
        amountIsValid(decimalAmount);
        if (decimalAmount.compareTo(ZERO.setScale(decimal, ROUND_FLOOR)) < 0) {
            throw new RuntimeException("invalid amount");
        }
        Map<String, BigDecimal> initiatorSpenders = allowed.get(initiator);
        if (initiatorSpenders == null) {
            Map<String, BigDecimal> newSpender = new HashMap<>();
            newSpender.put(spender, decimalAmount);
            allowed.put(initiator, newSpender);
        } else {
            BigDecimal spenderAmount = initiatorSpenders.get(spender);
            initiatorSpenders.put(spender, spenderAmount == null ? decimalAmount : spenderAmount.add(decimalAmount));
        }
    }

    @Override
    public boolean burn(String amount) {
        contractIsNotFrozen();
        BigDecimal decimalAmount = toBigDecimal(amount);
        amountIsValid(decimalAmount);
        if (decimalAmount.compareTo(ZERO.setScale(decimal, ROUND_FLOOR)) < 0) {
            throw new RuntimeException("invalid amount");
        }
        if (!initiator.equals(owner))
            throw new RuntimeException("can not burn tokens! The wallet " + initiator + " is not owner");
        if (totalCoins.compareTo(decimalAmount) < 0) totalCoins = ZERO;
        else totalCoins = totalCoins.subtract(decimalAmount);
        return true;
    }

    @Override
    public boolean buyTokens(String amount) {
        return false;
    }

    private void contractIsNotFrozen() {
        if (frozen) throw new RuntimeException("unavailable action! The smart-contract is frozen");
    }

    private void amountIsValid(BigDecimal decimalAmount) {
        if (decimalAmount.compareTo(ZERO.setScale(decimal, ROUND_FLOOR)) < 0) {
            throw new RuntimeException("invalid amount");
        }
    }

    private void initiatorIsRegistered() {
        if (!balances.containsKey(initiator))
            throw new RuntimeException("unavailable action! The wallet " + initiator + " is not registered");
    }

    private BigDecimal toBigDecimal(String stringValue) {
        return new BigDecimal(stringValue).setScale(decimal, ROUND_FLOOR);
    }

    private BigDecimal getTokensBalance(String address) {
        return balances.get(address);
    }
}
`;
        return source;
      },

      getSmartExampleSourceCode: function () {
        let source = `
package com.example.contract;
import com.credits.scapi.annotations.*;
import com.credits.scapi.v2.SmartContract;
import com.credits.scapi.v1.*;
import java.math.BigDecimal;
public class SmartContractName extends SmartContract {
  public SmartContractName() {
  }

  @Override
  public String payable(BigDecimal amount, byte[] userData) {
    return null;
  }

  @Getter
  public String hello() {
    return "Hello!";
  }

  @Getter
  public String helloWithParam(String msg) {
    return "Hello! " + msg;
  }
}`;
        return source;
      }

    },
    computed: {
      ...mapState({
        createTokenData: state => state.createSmartData,
        privateKey: state => state.privateKey,
        publicKey: state => state.publicKey,
        createSmartData: state => state.createSmartData,
        networkAlias: s => s.networkAlias
      })
    },
    destroyed() {
    }
  };
</script>
